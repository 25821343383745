jQuery(document).ready(function ($) {
    $(function () {
        let slotsForFourteenDays = {};
        let slotsForFourteenDaysCombined = {};
        var renderShowMoreButton = true;
        let hasOneCalendar = true;

        const today = new Date();
        let minDate = today;
        const dayOfWeek = today.getDay();

        if (dayOfWeek === 0) {
            minDate = new Date(today.setDate(today.getDate() + 1));
        } else if (dayOfWeek === 6) {
            minDate = new Date(today.setDate(today.getDate() + 2));
        }



        $('.get_demo .gform_next_button').on('click', async () => {
            loaderState(true);
            await getTimesFromMS({time: $('#input_45_12').val()});
            $("#form__calendar").datepicker("option", "minDate",minDate);
            $("#form__calendar").datepicker("option", "maxDate", new Date(Math.max(...Object.keys(slotsForFourteenDays).map(dateStr => new Date(dateStr)))));
            updateUIWithSlots($("#form__calendar").val())
        });

        $(document).on('gform_page_loaded', async function (event, form_id, current_page) {
            if (current_page == 2 && form_id === 45) {
                loaderState(true);

                const datePicker = $("#form__calendar").datepicker({
                    minDate: minDate,
                    maxDate: new Date(Math.max(...Object.keys(slotsForFourteenDays).map(dateStr => new Date(dateStr)))),
                    beforeShowDay: function (date) {
                        var day = date.getDay();
                        return [(day !== 0 && day !== 6), ''];
                    }
                });
                await fetchTimeZones();
                const selectTimeZone = $("#timezones");
                updateUIWithSlots(datePicker.val())
                selectTimeZone.on('change', async () => {
                    loaderState(true);
                    await getTimesFromMS(JSON.parse(selectTimeZone.val()));
                    updateUIWithSlots(datePicker.val());
                    putValuesInInputs(datePicker.val(), selectTimeZone.val())
                })
                datePicker.on('change', () => {
                    renderShowMoreButton = true;
                    updateUIWithSlots(datePicker.val())
                    putValuesInInputs(datePicker.val(), selectTimeZone.val())
                })
                loaderState(false);
                $('.form__right__show-more').on('click', async () => {
                    const btn = document.querySelector('.form__right__show-more');
                    const datePicker = $("#form__calendar");
                    btn.style.display = 'none';
                    if (btn.classList.contains('shows_more')) {
                        btn.classList.remove('shows_more');
                        btn.innerText = 'Show More Time'
                        updateUIWithSlots(datePicker.val());
                    } else {
                        const selectTimeZone = $("#timezones");
                        btn.classList.add('shows_more');
                        if (Object.keys(slotsForFourteenDaysCombined).length === 0) {
                            loaderState(true);
                            await getTimesFromMS(JSON.parse(selectTimeZone.val()));
                        }
                        updateUIWithSlots(datePicker.val());
                    }
                })

                putValuesInInputs(datePicker.val(), selectTimeZone.val())
            }
        });

        function getTimesFromMS(preselectedTimeZone) {
            renderShowMoreButton = true;
            const showMore = document.querySelector('.form__right__show-more').classList.contains('shows_more');
            return new Promise((resolve, reject) => {
                loaderState(true)
                const fields = {
                    'action': 'get_calendar_data',
                    'data': {
                        country: $('.calendar_country select').val(),
                        option: $('.recruting select').val(),
                        timezone: preselectedTimeZone,
                        multiple: showMore
                    }
                };

                jQuery.post(myScriptParams.ajax_url, fields, function (response) {
                    const res = JSON.parse(response);
                    if (showMore) {
                        slotsForFourteenDaysCombined = res.slots;

                        if (slotsForFourteenDaysCombined[$("#form__calendar").val()].length === 0) {
                            renderShowMoreButton = false;
                        }
                    } else {
                        slotsForFourteenDays = res.slots;
                    }

                    if (res.show_more_button) {
                        hasOneCalendar = true;
                    } else {
                        hasOneCalendar = false;
                    }

                    $("#form__calendar").datepicker("option", "minDate", minDate);
                    $("#form__calendar").datepicker("option", "maxDate", new Date(Math.max(...Object.keys(slotsForFourteenDays).map(dateStr => new Date(dateStr)))));

                    loaderState(false)
                    resolve();
                });
            });
        }

        function loaderState(state) {
            const loader = document.querySelector('.form__loader');
            if (state) {
                loader.classList.add('active');
            } else {
                loader.classList.remove('active');
            }
        }

        function updateUIWithSlots(date) {
            document.querySelector('.form__right h4').style.display = 'block';
            const btn = document.querySelector('.form__right__show-more');
            const slots = btn.classList.contains('shows_more') ? slotsForFourteenDaysCombined : slotsForFourteenDays
            const container = $('.form__right__times');
            container.empty();
            if (slots[date]) {
                slots[date].forEach((time) => {
                    var div = $('<div></div>').text(time.time);
                    div.addClass('time-slot');
                    $(div).on("click", function () {
                        container.find('.time-slot').each((idx, el) => {
                            $(el).removeClass('active');

                            $('#gform_submit_button_45').addClass('active');
                        })
                        $('#input_45_14').val(time.time)
                        $('#input_45_13').val(time.calendar_id);
                        div.addClass('active');
                    });
                    container.append(div);
                });
            }

            let timeSLots = document.querySelectorAll('.time-slot');
            if (timeSLots.length < 1) {
                btn.style.display = 'none';
                document.querySelector('.form__right h4').style.display = 'none';

                if (renderShowMoreButton && hasOneCalendar) {
                    container.append('<div class="no-time"><p class="big-text">No times available. Please select another day.</p><p class="small-text">Really need it on this day?</p><p class="small-text"><span class="hidden-show-more">Click here</span> to check again.</p></div>')
                } else {
                    container.append('<div class="no-time"><p class="big-text">No times available. Please select another day.</p></div>')
                }

                $('.hidden-show-more').on('click', async () => {
                    const btn = document.querySelector('.form__right__show-more');
                    const datePicker = $("#form__calendar");
                    btn.style.display = 'none';

                    const selectTimeZone = $("#timezones");
                    btn.classList.add('shows_more');
                    if (Object.keys(slotsForFourteenDaysCombined).length === 0 || slotsForFourteenDaysCombined[$("#form__calendar").val()].length === 0) {
                        loaderState(true);
                        await getTimesFromMS(JSON.parse(selectTimeZone.val()));
                    }
                    updateUIWithSlots(datePicker.val());
                })
            } else {
                if (hasOneCalendar) {
                    btn.style.display = 'block';
                }else {
                    btn.style.display = 'none';
                }
            }
        }

        function fetchTimeZones() {
            return new Promise((resolve, reject) => {
                let preselectedTimeZone;
                const selectTimeZone = $("#timezones");
                const userTimezone = $('#input_45_12').val();

                jQuery.post(myScriptParams.ajax_url, {'action': 'get_timezone_data'}, function (response) {
                    const res = JSON.parse(response);
                    var transformedData = [];

                    for (const [key, value] of Object.entries(res)) {
                        transformedData.push({value: {time: key, text: value}, text: value});
                    }

                    transformedData.forEach(item => {
                        const option = new Option(item.text, JSON.stringify(item.value));
                        if (item.value.time === userTimezone) {
                            preselectedTimeZone = item.value;
                            option.selected = true
                        }
                        selectTimeZone.append(option);
                    });
                    resolve(preselectedTimeZone);
                });
            });
        }

        (() => {
            if ($('.user-ms-bookings').length > 0 && $('.user-ms-bookings .img').length > 0) {

                let params = getQueryParams(window.location.href);
                const dateParts = params.start_date.split('/');
                const formattedDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
                params.start_date = formattedDate.toLocaleDateString('en-US', {
                    weekday: 'long', // "Wednesday"
                    year: 'numeric', // "2024"
                    month: 'long', // "January"
                    day: 'numeric' // "31"
                });

                $('.user-ms-bookings .time span').text(params.start_time);
                $('.user-ms-bookings .date').text(params.start_date);
                $('.user-ms-bookings .comment span').text(JSON.parse(params.start_timezone).text);
                $('.user-ms-bookings .img img').attr("src",params.image_url);
                $('.user-ms-bookings .name').text(params.calendar_name);
                $('.user-ms-bookings .top-text span').text(params.customer_name);
            }
        })();

    });

    function putValuesInInputs(date, timezone) {
        $('#input_45_11').val(date)
        $('#input_45_12').val(timezone)
    }

    // function getMinimumDate(slotsForFourteenDays){
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    //     let minDate = 0;
    //     let foundNextAvailableDay = false;
    //
    //     for (let dateStr in slotsForFourteenDays) {
    //         let date = new Date(dateStr);
    //         if (date >= today) {
    //             if (slotsForFourteenDays[dateStr].length > 0) {
    //                 if (date.getTime() === today.getTime()) {
    //                     foundNextAvailableDay = true;
    //                     break;
    //                 } else if (!foundNextAvailableDay) {
    //                     minDate = date;
    //                     foundNextAvailableDay = true;
    //                 }
    //             }
    //         }
    //     }
    //     return minDate;
    // }

    function getQueryParams(url) {
        let queryParams = {};
        // Create a link in the DOM and set its href to the url
        let anchor = document.createElement('a');
        anchor.href = url;
        // Use the anchor's search property to get the query string
        let queryStrings = anchor.search.substring(1);
        let params = queryStrings.split('&');

        for (let i = 0; i < params.length; i++) {
            let pair = params[i].split('=');
            queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return queryParams;
    }
});

// thank you page
jQuery(document).ready(function ($) {
    function updateHeight() {
        if ($('#ty-left').length && $(window).width() >= 768) {
            var height = $('#ty-left').height();
            $('#ty-wrapper').height(height);
        }
    }

    updateHeight();

    setInterval(updateHeight, 100);


    if ($('#ty-left').length) {
        $('#ty-wrapper').append(`
        <div class="user user-ms-bookings">
            <div class="img">
                <img src="https://picsum.photos/80/300" alt="">
            </div>
            <div class="content">
                <p class="top-text">Upcoming booking for <span>longo</span></p>
                <p class="title">Tracker Demo</p>
                <p class="name">Michael Michael</p>
                <p class="time"><span>4:45 pm</span> (1 hour)</p>
                <p class="date">Wednesday, January 31, 2024</p>
                <p class="comment">All times are in <span>(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</span></p>
            </div>
        </div>
    `);
    }
});
